import React from 'react';

const tags = {
    ALL: 'all',
    BANNER: 'banner',
    EMAIL: 'email',
    WEB_DESIGN: 'web'
};

const data = {
    tabs: [
        {
            tag: tags.ALL,
            label: 'All',
        },
        {
            tag: tags.EMAIL,
            label: 'Email',
        },
        {
            tag: tags.WEB_DESIGN,
            label: 'Web',
        },

    ],
    projects: [
        {
            title: 'TJX',
            url: 'https://www.tjx.com/',
            image: {
                url: 'assets/img/portfolio/portfolio-tjx-web.png',
                alt: '',
            },
            tags: [tags.ALL, tags.WEB_DESIGN],
            topLabel: 'WEB',
        },
        {
            title: 'Bechtel',
            url: 'https://www.bechtel.com/',
            image: {
                url: 'assets/img/portfolio/portfolio-bechtel-web.png',
                alt: 'Bechtel',
            },
            tags: [tags.ALL, tags.WEB_DESIGN],
            topLabel: 'WEB',
        },
        {
            title: 'Everett Bank',
            url: '/',
            image: {
                url: 'assets/img/portfolio/portfolio-everett-web.png',
                alt: '',
            },
            tags: [tags.ALL, tags.EMAIL],
            topLabel: 'EMAIL',
        },
        {
            title: 'First Citizens Bank',
            url: '/',
            image: {
                url: 'assets/img/portfolio/portfolio-first-citizens-email.png',
                alt: '',
            },
            tags: [tags.ALL, tags.EMAIL],
            topLabel: 'EMAIL',
        },
        {
            title: 'Boys and Girls Club',
            url: '/',
            image: {
                url: 'assets/img/portfolio/portfolio-boys-and-girls-email.png',
                alt: '',
            },
            tags: [tags.ALL, tags.EMAIL],
            topLabel: 'EMAIL',
        },
        {
            title: 'Eleven',
            url: 'https://www.eleven.net/',
            image: {
                url: 'assets/img/portfolio/portfolio-eleven-web.png',
                alt: '',
            },
            tags: [tags.ALL, tags.WEB_DESIGN],
            topLabel: 'WEB',
        },
        {
            title: 'Redgate',
            url: 'https://redgate-re.com/',
            image: {
                url: 'assets/img/portfolio/portfolio-redgate-web.png',
                alt: '',
            },
            tags: [tags.ALL, tags.WEB_DESIGN],
            topLabel: 'WEB',
        },
        {
            title: 'Jibo',
            url: 'https://redgate-re.com/',
            image: {
                url: 'assets/img/portfolio/portfolio-jibo-email.png',
                alt: '',
            },
            tags: [tags.ALL, tags.EMAIL],
            topLabel: 'EMAIL',
        },
        
    ],
};

const ProjectSection = () => {
    const [selectedTag, setSelectedTag] = React.useState(tags.ALL);
    return (
        <section className="project-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section-title">
                            <h6>Recent Works</h6>
                            <h2>Our Portfolio</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="project-list">
                            <ul className="nav" id="project-flters">
                                {data.tabs.map((tab) => {
                                    return (
                                        <li
                                            key={tab.tag}
                                            className={`filter ${
                                                tab.tag === selectedTag && 'filter-active'
                                            }`}
                                        >
                                            <button onClick={() => setSelectedTag(tab.tag)}>
                                                {tab.label}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="project-container container">
                        <ul className="row">
                            {data.projects
                                .filter((project) => project.tags.includes(selectedTag))
                                .map((project) => {
                                    return (
                                        <li
                                            href={project.url}
                                            className="col-lg-3 col-md-6 project-grid-item all branding webdesign"
                                        >
                                            <a
                                                href={project.url}
                                                aria-label={`View ${project.title}`}
                                                className="project-item"
                                            >
                                                <img
                                                    src={project.image.url}
                                                    alt={project.image.alt}
                                                    height={300}
                                                    width={300}
                                                />
                                                <div className="project-content-overlay">
                                                    <p>{project.topLabel}</p>
                                                    <h3>
                                                        <a href={project.url} target="_blank">
                                                            {project.title}
                                                        </a>
                                                    </h3>
                                                </div>
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectSection;
