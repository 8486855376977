import React from 'react';
import { useInView } from 'react-intersection-observer';
const ProgressSection = () => {
    const [ref, inView] = useInView({ threshold: 0.5 });
    return (
        <section id="about-us" className="about-area bg-grey section-padding">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="about-content">
                            <div className="about-content-text">
                                <h6>About Lotus Dev</h6>
                                <h2>
                                    We are web industry professionals.
                                </h2>
                                <p>
                                    With over four decades combined experience in advertising, consulting, Fortune XXs and high growth companies. Our joy for clean web is your benefit. Give us the laundry list and we will put your mind at ease.
                                </p>
                                <div
                                    className={`skills ${inView ? 'skills--in-view' : ''}`}
                                    ref={ref}
                                >
                                    <div className="skill-item">
                                        <h6>
                                            Web<em>90%</em>
                                        </h6>
                                        <div className="skill-progress">
                                            <div
                                                className="progress"
                                                style={{ '--progress': '90%' }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h6>
                                            Advertising<em>85%</em>
                                        </h6>
                                        <div className="skill-progress">
                                            <div
                                                className="progress"
                                                style={{ '--progress': '75%' }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h6>
                                            Enterprise & Operations<em>80%</em>
                                        </h6>
                                        <div className="skill-progress">
                                            <div
                                                className="progress"
                                                style={{ '--progress': '80%' }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-btn-box">
                                    <a className="default-btn project-btn-1" href="#contact-us">
                                        Learn More<span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 col-md-12 col-sm-12">
                        <div className="about-image">
                            <img src="assets/img/about-us-655x600.jpg" alt="" />
                            <div className="years-design">
                                <h2>{'>'}50</h2>
                                <h5>Years Experience</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProgressSection;
