import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ description, meta, title }) => {
    meta = meta ? meta : [];
    description = description ? description : '';

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    title = title || site.siteMetadata.title;

    const extraTags = [...meta];
    const socialMediaTitle =
        site.siteMetadata.title === title ? title : `${title} | ${site.siteMetadata.title}`;

    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
            title={title}
            titleTemplate={
                site.siteMetadata.title === title ? `%s` : `%s | ${site.siteMetadata.title}`
            }
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: socialMediaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },

                {
                    name: `twitter:title`,
                    content: socialMediaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                ...extraTags,
            ]}
        />
    );
};

export default Seo;
