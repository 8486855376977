import React from 'react';
import Seo from '../components/Seo';
import ProgressSection from '../components/ProgressSection';
import ProjectSection from '../components//ProjectSection';

const Index = () => {
    return (
        <>
            <Seo title="Homepage" />
            <div className="home-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="main-banner-content">
                                        <h1>
                                            External Website Maintence for Real Estate and Investment Management
                                        </h1>
                                        <p>
                                            Reliable partners for all website: support, marketing emails, content
                                            updates, and development. We specialize in supporting businesses like yours!
                                        </p>
                                        <div className="banner-btn">
                                            <a href="#about-us" className="default-btn-one">
                                                About Us
                                                <span></span>
                                            </a>
                                            <a className="default-btn" href="#contact-us">
                                                Contact Us
                                                <span></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="banner-image">
                                        <img src="assets/img/lotus.png" alt="Lotus Dev Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creative-shape">
                    <img src="assets/img/waves.svg" alt="svg shape" />
                </div>
            </div>

            <section id="services" className="services-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="section-title">
                                <h6>What We Provide</h6>
                                <h2>IT Solutions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row cards-row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-project-management"></i>
                                </div>
                                <h3>Maintenance</h3>
                                <p>
                                    Leave the busywork to us. Design changes, content updates. Blog
                                    posts. You name it.
                                </p>
                                <div className="services-btn-link">
                                    <a href="#contact-us" className="services-link">
                                        Request a quote
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-programming"></i>
                                </div>
                                <h3>Web Development</h3>
                                <p>
                                    Still don't have a website or you need to replace one? We have
                                    you covered.
                                </p>
                                <div className="services-btn-link">
                                    <a href="#contact-us" className="services-link">
                                        Request a quote
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-cpu-1"></i>
                                </div>
                                <h3>Ecommerce Support</h3>
                                <p>
                                    We specialize in Shopify sites. Let us know if you need help
                                    with yours.
                                </p>
                                <div className="services-btn-link">
                                    <a href="#contact-us" className="services-link">
                                        Request a quote
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-coding"></i>
                                </div>
                                <h3>SEO Optimization</h3>
                                <p>
                                    We can speed up your site, tune up your keywords, improve your
                                    search engine page rankings and more. Let us know what your
                                    goals are.
                                </p>
                                <div className="services-btn-link">
                                    <a href="#contact-us" className="services-link">
                                        Request a quote
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-mobile-app"></i>
                                </div>
                                <h3>Banners</h3>
                                <p>
                                    You know it, we know it, banners make the world go round! No
                                    problem. Bring it on!
                                </p>
                                <div className="services-btn-link">
                                    <a href="#contact-us" className="services-link">
                                        Request a quote
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-cloud"></i>
                                </div>
                                <h3>Marketing</h3>
                                <p>
                                    Need support with your newsletter/chatbot install and
                                    configs/CRM integration? Great! We love that stuff! :)
                                </p>
                                <div className="services-btn-link">
                                    <a href="#contact-us" className="services-link">
                                        Request a quote
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ProjectSection />
            <ProgressSection />
            <section id="why-us" className="overview-section section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="overview-image">
                                <img src="assets/img/why-choose-635x575.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="overview-content">
                                <h6>Why Choose Us?</h6>
                                <h2>We align to your needs and level of comfort.</h2>
                                <p>
                                    Whether you want daily scrums with task management tracking, or
                                    a quarterly email exchange, we can fit to your pace and culture.
                                </p>
                                <ul className="features-list">
                                    <li>
                                        {' '}
                                        <span>Wordpress</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>PSD to HTML</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Shopify</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>HTML Emails</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Bug Fixing</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Website Maintenance</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="trust-us" className="overview-section pt-70 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="overview-content">
                                <h6>WHY TRUST US?</h6>
                                <h2>We are discreet and QA all work on secured test servers.</h2>
                                <p>
                                    Our quality assurance practices make sure the work is tested in
                                    a test environment against all requirements and accepted by our
                                    clients using a four eye principle.
                                </p>
                                <ul className="features-list">
                                    <li>
                                        {' '}
                                        <span>QA Testing</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Password protected test servers</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Compliance with Data Security</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>SSL Encryption</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Discreet practices</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Four eye principle</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="overview-image-2">
                                <img src="assets/img/why-trust-635x575.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="hire-us" className="hire-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-12">
                            <div className="hire-content">
                                <h6>Want to work with us?</h6>
                                <h2>Super power your ops. and let us do the technical stuff.</h2>
                                <p>
                                    We're always excited to hear about what kinds of projects are
                                    coming down the pipe, or simply when you need to hand off some
                                    extra IT stuff to the devs. We're here to help, get in touch
                                    now.
                                </p>
                                <div className="hire-btn">
                                    <a className="default-btn" href="#contact-us">
                                        Contact Us<span></span>
                                    </a>
                                </div>
                                <div className="hire-btn">
                                    <a className="default-btn" href="https://calendly.com/lotus-dev-thomas/15-minute-consultation-website-maintainence">
                                        Book a Consultation<span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Index;
